#root {
  min-height: 100vh;
}
html,
body,
#root {
  min-height: 100% !important;
  height: 100%;
  font-family: Roboto !important;
}

textarea:focus, input:focus, select:focus{
  box-shadow: none!important;
}

textarea, input.form-control, select{
  border-left: 4px solid #2F7AC5!important;
  border-radius: 0!important;
}